import React, { useEffect, useState } from "react";
import { Auth, API } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
//import { useIdleTimerContext } from "react-idle-timer";
import {
  Box,
  Button,
  Stack,
  Paper,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import PageviewOutlinedIcon from "@mui/icons-material/Pageview";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PlaylistPlayOutlinedIcon from "@mui/icons-material/PlaylistPlayOutlined";

import { useSentencePlay } from "../_hooks/useSentencePlay";
import { SentencePlay } from "../sentences";
import { SequenceDisplayDialog } from "../sequences";
import { useTheme } from "@mui/material";
import { getIsPaid } from "../_services/user.service";
import { WindowTitle } from "../_helpers/gvlang-components";

import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

export { SentenceSequencePlay };

function SentenceSequencePlay() {
  const params = useParams();
  const cognitoUser = Auth.user.username;
  const { id } = params;
  const theme = useTheme();
  //const idleTimer = useIdleTimerContext();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("gvLangUser"));
  const { prepareSentence } = useSentencePlay();
  const [lesson, setLesson] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [playSteps, setPlaySteps] = useState([]);
  const [autoPlay, setAutoPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [itemEls, setItemEls] = useState([]);
  const sentenceCount = lesson ? lesson.sentences.length : 0;
  const isPaid = getIsPaid(user);

  const [sequenceDisplayOpen, setSequenceDisplayOpen] = useState(false);
  const handleSequenceDisplayClick = () => setSequenceDisplayOpen(true);
  const handleSequenceDisplayClose = () => setSequenceDisplayOpen(false);

  function handlePlayAll() {
    setAutoPlay(true);
    playIndex(selectedIndex || 0);
  }

  function playIndex(ix) {
    // at end of list
    if (ix < 0 || ix > sentenceCount - 1) {
      setSelectedIndex(null);
      setAutoPlay(false);
      return;
    }
    // otherwise, play it
    const item = lesson.sentences[ix];
    handleSentenceSelect(item, ix);
    //idleTimer.reset();
  }

  const handleMove = (direction) => {
    let newIx;
    if (selectedIndex === null) {
      handleSentenceSelect(lesson.sentences[0], 0);
    } else {
      if (direction === "forward") {
        if (selectedIndex + 1 < lesson.sentences.length) {
          newIx = selectedIndex + 1;
          handleSentenceSelect(lesson.sentences[newIx], newIx);
        }
      } else {
        if (selectedIndex > 0) {
          newIx = selectedIndex - 1;
          handleSentenceSelect(lesson.sentences[newIx], newIx);
        }
      }
    }
  };

  function handleSentenceSelect(s, index) {
    if (!isPlaying) {
      const seqText = lesson.usesSeq.seqText;
      const { transText, isInstruction } = s;
      const { status, segments, steps } = prepareSentence(s, seqText);
      setSelectedIndex(index);

      let objAdd = {};
      if (status === "OK") {
        const ary = steps.map((step) => {
          const langOT = step.slice(0, 1);
          if (langOT === "T" || isInstruction) {
            objAdd = {
              voice: lesson.transVoice,
              seg1: transText,
              seg2: "",
              seg3: "",
              reps: step.slice(4, 6),
              deco: step.slice(6, 7),
              langOT: langOT,
              isInstruction: isInstruction,
            };
          } else {
            objAdd = {
              voice: lesson.origVoice,
              seg1: step.slice(1, 2) === "t" ? segments[0] : "",
              seg2: step.slice(2, 3) === "t" ? segments[1] : "",
              seg3: step.slice(3, 4) === "t" ? segments[2] : "",
              reps: step.slice(4, 6),
              deco: step.slice(6, 7),
              langOT: langOT,
              isInstruction: isInstruction,
            };
          }
          return objAdd;
        });
        setPlaySteps(ary);
      }
    }
  }

  function onSentenceStart() {
    setIsPlaying(true);
    itemEls[selectedIndex].current.scrollIntoView();
  }

  async function onSentenceComplete(instruction) {
    setIsPlaying(false);
    try {
      await API.post(`historyApi`, "/history/add-item", {
        body: {
          ownerId: cognitoUser,
          lessonName: lesson.name,
          objectType: "sentence",
          origText: lesson.sentences[selectedIndex].origText,
          origVoice: lesson.origVoice,
          transText: lesson.sentences[selectedIndex].transText,
          transVoice: lesson.transVoice,
          actionTaken: "played",
        },
      });
    } catch (err) {
      console.log("err response", err);
    }

    if (instruction === "stop") {
      setAutoPlay(false);
      return;
    }
    if (autoPlay) {
      const newIndex = selectedIndex + 1;
      playIndex(newIndex);
      if (newIndex < sentenceCount - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
    }
  }

  useEffect(() => {
    if (id) {
      const getLesson = async () => {
        try {
          const response = await API.get(
            "lessonsApi",
            `/lessons/one-lesson?id=${id}`
          );
          const apiLesson = response.appData.lesson;
          // const historyResp = await API.get(
          //   "historyApi",
          //   `/history/get-list-lesson?ownerid=${cognitoUser}&lessonname=${apiLesson.name}`
          // );
          // const apiHistory = historyResp.appData.history;
          if (apiLesson) {
            setLesson(apiLesson);
            setItemEls(
              Array(apiLesson.sentences.length)
                .fill(0)
                .map((i) => React.createRef())
            );
            // if (apiHistory.length) {
            //   setHistoryLast(apiHistory[0]);
            //   enqueueSnackbar(`Resuming at: ${apiHistory[0].origText}`, {
            //     variant: "info",
            //     autoHideDuration: 2000,
            //   });
            // }
          }
        } catch (error) {
          if (error.response) {
            console.log(error.response.data);
          }
        }
      };
      getLesson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (historyLast && lesson?.sentences) {
  //     const setFromLast = () => {
  //       var index = lesson.sentences
  //         .map(function (e) {
  //           return e.origText;
  //         })
  //         .indexOf(historyLast.origText);
  //       if (index > -1) {
  //         handleSentenceSelect(lesson.sentences[index], index);
  //         if (itemEls) {
  //           itemEls[index].current.scrollIntoView();
  //         }
  //       }
  //     };
  //     setFromLast();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [historyLast, lesson?.sentences]);

  const renderSentence = (item, index) => {
    return (
      <ListItem
        key={item._id}
        sx={{ padding: "0px 5px", borderBottom: "1px solid grey" }}
        ref={itemEls[index]}
      >
        <ListItemButton
          selected={index === selectedIndex ? true : false}
          onClick={() => handleSentenceSelect(item, index)}
          sx={{
            padding: "5px",
            borderLeft: item.isInstruction ? "4px solid gold" : "inherit",
            display: "inline",
            backgroundColor: "inherit",
            "&.Mui-selected": {
              backgroundColor: "#f0e68c",
            },
            "&.Mui-focusVisible": {
              backgroundColor: theme.palette.custom.main,
            },
            ":hover": {
              backgroundColor: "#bdb76b",
            },
            "&.Mui-disabled": {
              background: theme.palette.custom.light,
              opacity: ".75",
              color: "#F5F5F5",
            },
          }}
        >
          <ListItemText
            primary={item.origText}
            primaryTypographyProps={{
              variant: "body1",
            }}
          />
          <ListItemText
            primary={item.transText}
            primaryTypographyProps={{
              variant: "body2",
              color: "#2f4f4f",
              fontWeight: 400,
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Paper sx={{ height: "90%" }}>
      <Stack>
        {lesson && (
          <>
            <Box sx={{ width: "1200px", overflow: "ellipsis" }} p={1}>
              <Stack
                direction="row"
                spacing={3}
                sx={{
                  height: "6%",
                  alignItems: "center",
                  padding: "8px 10px",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <WindowTitle>Play with Sequence</WindowTitle>
                <Typography variant="body1">{lesson.name}</Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="body2" sx={{ display: "inline" }}>
                    {lesson.origVoice}
                  </Typography>
                  <Box
                    sx={{
                      display: "inline",
                      width: 20,
                      height: 20,
                      backgroundColor: user.preferences.origColor,
                    }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="body2" sx={{ display: "inline" }}>
                    {lesson.transVoice}
                  </Typography>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: user.preferences.transColor,
                    }}
                  />
                </Stack>
                <Button
                  onClick={() =>
                    navigate(`/app/lessons/sentences/${lesson._id}`)
                  }
                  variant="text"
                  size="small"
                  startIcon={<ArrowOutwardIcon />}
                  color="secondary"
                >
                  {sentenceCount} Sentences
                </Button>
              </Stack>
            </Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                backgroundColor: theme.palette.grey[400],
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  height: "95%",
                  marginTop: "8px",
                  borderRadius: "10px",
                }}
              >
                <Stack direction="row" spacing={3} sx={{ marginLeft: "50px" }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handlePlayAll}
                    startIcon={<PlaylistPlayOutlinedIcon />}
                    disabled={!isPaid || isPlaying}
                  >
                    Play all
                  </Button>
                  <Button
                    onClick={handleSequenceDisplayClick}
                    variant="text"
                    size="small"
                    startIcon={<PageviewOutlinedIcon />}
                    sx={{ marginRight: "15px" }}
                  >
                    View sequence
                  </Button>
                </Stack>
                {lesson.sentences && (
                  <List
                    sx={{
                      maxHeight: "75vh",
                      overflowY: "scroll",
                      margin: "15px 0px 10px 10px",
                      padding: "3px",
                      backgroundColor: "white",
                      borderRadius: "6px",
                    }}
                  >
                    {lesson.sentences.map(renderSentence)}
                    <Box
                      key="-1"
                      sx={{
                        height: "5vh",
                        backgroundColor: theme.palette.custom.main,
                        typography: "caption",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      end of list
                    </Box>
                  </List>
                )}
              </Box>
              <Box
                width="55%"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    typography: "caption",
                    padding: "8px",
                    fontWeight: 500,
                    marginTop: "8px",
                  }}
                >
                  <IconButton
                    disabled={isPlaying || selectedIndex === 0}
                    onClick={() => handleMove("back")}
                    sx={{
                      marginRight: "15px",
                      fontSize: "35px",
                      height: "30px",
                      backgroundColor: theme.palette.secondary.main,
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.dark,
                      },
                      borderRadius: "4px",
                    }}
                  >
                    <ChevronLeftOutlinedIcon />
                  </IconButton>
                  {playSteps.length
                    ? `Sentence ${selectedIndex + 1} of ${sentenceCount}`
                    : ""}
                  {lesson.sentences[selectedIndex]?.isInstruction &&
                    "  --Instruction--"}
                  <IconButton
                    disabled={
                      isPlaying || selectedIndex >= lesson.sentences?.length - 1
                    }
                    onClick={() => handleMove("forward")}
                    sx={{
                      marginLeft: "15px",
                      fontSize: "35px",
                      height: "30px",
                      backgroundColor: theme.palette.secondary.main,
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.dark,
                      },
                      borderRadius: "4px",
                    }}
                  >
                    <ChevronRightOutlinedIcon />
                  </IconButton>
                </Box>
                {lesson.usesSeq && (
                  <Stack spacing={3}>
                    {playSteps && (
                      <SentencePlay
                        playSteps={playSteps}
                        autoPlay={autoPlay}
                        onStart={onSentenceStart}
                        onComplete={onSentenceComplete}
                        isPaid={isPaid}
                      />
                    )}
                  </Stack>
                )}
              </Box>
            </Stack>
          </>
        )}
      </Stack>
      <SequenceDisplayDialog
        open={sequenceDisplayOpen}
        onClose={handleSequenceDisplayClose}
        name={lesson?.usesSeq.name}
        seqText={lesson?.usesSeq.seqText}
        origVoice={lesson?.origVoice}
        transVoice={lesson?.transVoice}
      />
    </Paper>
  );
}
