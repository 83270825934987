import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material";

import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import RemoveFromQueueOutlinedIcon from "@mui/icons-material/RemoveFromQueueOutlined";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

import { enqueueSnackbar } from "notistack";
import { usePolly } from "../_hooks/usePolly.js";
import { getIsPaid } from "../_services/user.service.js";
import { WindowTitle } from "../_helpers/gvlang-components.jsx";

export { SentenceFreePlay };

function SentenceFreePlay(props) {
  const theme = useTheme();
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("gvLangUser"));
  const isPaid = getIsPaid(user);
  const [phraseList, setPhraseList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { playPhrase } = usePolly();
  const [itemEls, setItemEls] = useState([]);
  const [clearForEach, setClearForEach] = useState(false);

  useEffect(() => {
    if (id) {
      const getLesson = async () => {
        try {
          const response = await API.get(
            "lessonsApi",
            `/lessons/one-lesson?id=${id}`
          );
          setLesson(response.appData.lesson);
          setItemEls(
            Array(response.appData.lesson.sentences.length)
              .fill(0)
              .map((i) => React.createRef())
          );
        } catch (error) {
          if (error.response) {
            console.log(error.response.data);
          }
        }
      };
      getLesson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedIndex !== null && selectedIndex > -1) {
      const origText = lesson?.sentences[selectedIndex].origText;
      const origVoice = lesson?.origVoice;
      if (origText) {
        const setFirstPhrase = () => {
          addPhrase(origText);
          itemEls[selectedIndex].current.scrollIntoView();
        };
        setFirstPhrase();
        playIt(origText, origVoice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const handleMove = (direction) => {
    if (clearForEach) {
      setPhraseList([]);
    }
    if (selectedIndex === null) {
      setSelectedIndex(0);
    } else {
      if (direction === "forward") {
        if (selectedIndex + 1 < lesson.sentences.length) {
          setSelectedIndex(selectedIndex + 1);
        }
      } else {
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1);
        }
      }
    }
  };

  async function playIt(phrase, voice) {
    if (!isPlaying && isPaid) {
      const waitTime = 2000 + (phrase.length / 20) * 1000;
      setIsPlaying(true);
      const playResult = await playPhrase({
        textToPlay: phrase,
        voiceStr: voice,
      });
      if (playResult.errors) {
        enqueueSnackbar(playResult.errors[0], { variant: "error" });
      }
      setTimeout(() => setIsPlaying(false), waitTime);
    }
  }

  function addPhrase(p) {
    if (p.length > 0) {
      let items = [...phraseList];
      items.unshift(p);
      setPhraseList(items);
    }
  }

  function handleMouseUp() {
    const phrase = window.getSelection().toString();
    addPhrase(phrase);
  }

  function handleSentenceClick(sentence, index) {
    if (clearForEach) {
      setPhraseList([]);
    }
    //playIt(sentence.origText, lesson.origVoice);
    setSelectedIndex(index);
  }

  const renderSentence = (item, index) => {
    return (
      <ListItem
        key={item._id}
        sx={{ padding: "0px 5px", borderBottom: "1px solid grey" }}
        ref={itemEls[index]}
      >
        <ListItemButton
          disabled={isPlaying}
          selected={index === selectedIndex ? true : false}
          onClick={() => handleSentenceClick(item, index)}
          sx={{
            padding: "5px",
            borderLeft: item.isInstruction ? "4px solid gold" : "inherit",
            display: "inline",
            backgroundColor: "inherit",
            "&.Mui-selected": {
              backgroundColor: theme.palette.custom.main,
            },
            "&.Mui-focusVisible": {
              backgroundColor: theme.palette.custom.main,
            },
            ":hover": {
              backgroundColor: theme.palette.custom.main,
            },
            "&.Mui-disabled": {
              background: theme.palette.custom.light,
              opacity: ".75",
              color: "inherit",
            },
          }}
        >
          <ListItemText
            primary={item.origText}
            primaryTypographyProps={{
              variant: "body1",
            }}
          />
          <ListItemText
            primary={item.transText}
            primaryTypographyProps={{
              variant: "body2",
              color: "#2f4f4f",
              fontWeight: 400,
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Paper sx={{ height: "90vh" }}>
      {lesson && (
        <>
          <Stack
            sx={{
              padding: "10px",
              overflow: "ellipsis",
              width: "1000px",
              height: "8%",
            }}
            direction="row"
            spacing={3}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <WindowTitle>Free Play</WindowTitle>
            <Typography variant="body1">Free Play: {lesson.name}</Typography>
            <Stack
              direction="row"
              spacing={1}
              display="flex"
              alignItems="center"
            >
              <Typography variant="body2" sx={{ display: "inline" }}>
                {lesson.origVoice}
              </Typography>
              <Box
                sx={{
                  display: "inline",
                  width: 20,
                  height: 20,
                  backgroundColor: user.preferences.origColor,
                }}
              />
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              display="flex"
              alignItems="center"
            >
              <Typography variant="body2" sx={{ display: "inline" }}>
                {lesson.transVoice}
              </Typography>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: user.preferences.transColor,
                }}
              />
            </Stack>

            <Button
              onClick={() => navigate(`/app/lessons/sentences/${lesson._id}`)}
              variant="text"
              size="small"
              startIcon={<ArrowOutwardIcon />}
              color="secondary"
            >
              {lesson?.sentenceCount} Sentences
            </Button>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            sx={{
              backgroundColor: theme.palette.grey[400],
              height: "90%",
            }}
          >
            <Box
              sx={{
                width: "50%",
                height: "100%",
                marginTop: "8px",
                borderRadius: "10px",
              }}
            >
              {lesson.sentences && (
                <List
                  sx={{
                    maxHeight: "100%",
                    overflowY: "scroll",
                    marginLeft: "10px",
                    padding: "3px",
                    backgroundColor: "white",
                    borderRadius: "6px",
                  }}
                >
                  {lesson.sentences.map(renderSentence)}
                  <Box
                    key="-1"
                    sx={{
                      height: "25px",
                      backgroundColor: theme.palette.custom.main,
                      typography: "caption",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    end of list
                  </Box>
                </List>
              )}
            </Box>
            <Stack>
              <Box
                sx={{
                  typography: "caption",
                  padding: "5px",
                  fontWeight: 500,
                  marginTop: "8px",
                }}
              >
                <IconButton
                  disabled={isPlaying || selectedIndex === 0}
                  onClick={() => handleMove("back")}
                  sx={{
                    marginRight: "15px",
                    fontSize: "35px",
                    height: "30px",
                    backgroundColor: theme.palette.secondary.main,
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.dark,
                    },
                    borderRadius: "4px",
                  }}
                >
                  <ChevronLeftOutlinedIcon />
                </IconButton>

                {lesson.sentences.length
                  ? `Sentence ${selectedIndex + 1} of ${
                      lesson.sentences.length
                    }`
                  : ""}
                <IconButton
                  disabled={
                    isPlaying || selectedIndex >= lesson.sentences?.length - 1
                  }
                  onClick={() => handleMove("forward")}
                  sx={{
                    marginLeft: "15px",
                    fontSize: "35px",
                    height: "30px",
                    backgroundColor: theme.palette.secondary.main,
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.dark,
                    },
                    borderRadius: "4px",
                  }}
                >
                  <ChevronRightOutlinedIcon />
                </IconButton>
              </Box>
              <Stack
                spacing="3px"
                direction="row"
                justifyContent="left"
                alignItems="center"
                marginTop="2px"
                marginBottom="10px"
              >
                <Box
                  component="span"
                  sx={{
                    p: 1,
                    borderRadius: "8px",
                    margin: "0px 0px",
                    padding: "1px",
                    width: "80%",
                  }}
                  onMouseUp={handleMouseUp}
                >
                  <Box
                    typography="caption"
                    display="block"
                    sx={{
                      marginBottom: "5px",
                      padding: "5px",
                      color: theme.palette.neutral.dark,
                      backgroundColor: theme.palette.neutral.light,
                      borderRadius: "6px",
                    }}
                  >
                    Select any part of TL to add it to the play area
                  </Box>
                  <Box
                    typography="body2"
                    sx={{
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                  >
                    {lesson?.sentences[selectedIndex]?.origText}
                  </Box>
                  <Box
                    sx={{
                      marginTop: "3px",
                      backgroundColor: "white",
                      color: "#2f4f4f",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography variant="body2">
                      {lesson?.sentences[selectedIndex]?.transText}
                    </Typography>
                  </Box>
                </Box>
              </Stack>

              <Box
                sx={{
                  p: 1,
                  borderRadius: "8px",
                  margin: "0px",
                  backgroundColor: "white",
                }}
              >
                <Stack direction="row" spacing={4}>
                  <Typography
                    variant="body1"
                    color={theme.palette.neutral.dark}
                  >
                    {isPaid ? "Play area" : "Play is disabled"}
                  </Typography>
                  <Button
                    sx={{ width: "100px" }}
                    size="small"
                    color="primary"
                    disabled={!phraseList.length || isPlaying}
                    variant="contained"
                    onClick={() => playIt(phraseList[0], lesson.origVoice)}
                  >
                    Play
                  </Button>
                  <Button
                    sx={{ width: "100px" }}
                    size="small"
                    disabled={!phraseList.length || isPlaying}
                    startIcon={<RemoveFromQueueOutlinedIcon />}
                    variant="outlined"
                    onClick={() => setPhraseList([])}
                  >
                    Clear
                  </Button>
                </Stack>

                <List
                  sx={{
                    maxHeight: "50vh",
                    overflowY: "scroll",
                    padding: "3px",
                    backgroundColor: "white",
                    borderRadius: "6px",
                  }}
                >
                  {phraseList &&
                    phraseList.map((p, index) => (
                      <ListItem key={index} sx={{ padding: "2px 8px 2px 8px" }}>
                        <IconButton
                          disabled={!isPaid || isPlaying}
                          size="medium"
                          sx={{
                            "&.Mui-disabled": {
                              color: theme.palette.neutral,
                            },
                            color: theme.palette.primary.dark,
                          }}
                          onClick={() => playIt(p, lesson.origVoice)}
                        >
                          <VolumeUpIcon />
                        </IconButton>
                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.dark}
                        >
                          {p}
                        </Typography>
                      </ListItem>
                    ))}
                </List>
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", height: "25px" }}
                >
                  <Checkbox
                    checked={clearForEach}
                    onChange={() => setClearForEach(!clearForEach)}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                  <Typography variant="caption" color="primary">
                    Clear for each new sentence
                  </Typography>
                </Stack>
              </Box>
              {isPlaying && (
                <Box
                  sx={{
                    margin: "8px",
                    padding: "8px",
                    typography: "body2",
                    width: "200px",
                    backgroundColor: theme.palette.custom.dark,
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "6px",
                  }}
                >
                  Playing...
                </Box>
              )}
            </Stack>
          </Stack>
        </>
      )}
    </Paper>
  );
}
